.container {
  position: relative;

  & > img {
    width: auto;
    height: auto;
    max-width: 81.5%;
    margin-left: 18.5%;
    max-height: 100%;
    object-fit: contain;
    visibility: hidden;
    transform: translateX(10%);
    opacity: 0;
    transition: transform ease-in 0.5s, opacity ease-in 0.3s;
  }

  & > .inputBox {
    margin: 0 17.5% 0 44%;
    height: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7%;
    z-index: 2;
    visibility: hidden;
    transform: translateY(20px);
    opacity: 0;
    transition: transform ease-in 0.5s, opacity ease-in 0.3s;
    //filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));

    backdrop-filter: blur(10px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.2);

    border-radius: min(1vw, 16px);

    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px;

    & > svg {
      max-width: 92%;
    }
  }

  .floatContainer {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    margin-top: 6%;
    width: 45%;
    background: rgba(255, 255, 255, 0.55);
    box-sizing: border-box;
    padding: min(2.5%, 30px);
    border-radius: 14px;
    box-shadow: 0 30px 50px 0 rgba(17, 18, 60, 0.25);
    transform: translateY(20px);
    backdrop-filter: blur(10px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);

    visibility: hidden;

    opacity: 0;
    transition: transform ease-in 0.5s, opacity ease-in 0.3s;

    & > img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
      //-webkit-backdrop-filter: blur(5px);
    }

    .imgMarginTop12 {
      margin-top: 12px;
    }

    .buttonBox {
      max-width: 240px;
      background-color: #ff8e04;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      border-radius: 16px;
      margin: 23px auto 20px auto;
      cursor: pointer;
      transition: all 0.3s;

      height: min(8vw, 64px) !important;
      font-size: min(2vw, 17px) !important;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);

      &:hover {
        background-color: #ea6f00;
        box-shadow: none;
      }

      & > svg {
        height: 62.5%;
        margin-right: 10px;
        //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
        //-webkit-backdrop-filter: blur(5px);
      }
    }
  }
}
