// 动画持续时间、动画延迟时间
@time1: 0.5s;
@delay1: 0s;

@time2: 0.3s;
@delay2: 0.5s;

@topGuidTime: 0.5s;
@topGuidDelay: 0.8s;

@inputBoxTime: 0.5s;
@inputBoxDelay: 1.3s;

.container {
  position: relative;
  text-align: right;

  & > .mainBg {
    width: auto;
    height: auto;
    max-width: 100%;
    padding-left: 5%;
    max-height: 100%;

    visibility: hidden;
    opacity: 0;
    transform: translateX(10%);
    transition: transform 0.5s linear, opacity 0.3s linear;
  }

  & > .main {
    width: 52%;
    height: auto;
    position: absolute;
    bottom: 4px;
    left: 25%;
    right: 0;
    z-index: 2;
    object-fit: contain;
    visibility: hidden;
    opacity: 1;
    transform: translateX(10%);
  }

  & > .mainBgAnimation {
    transition: transform 0.5s linear, opacity 0.3s linear;
    animation: blockTransition1 @time1 linear forwards @delay1;
  }

  .topGuidBox {
    position: absolute;
    top: calc(9% + 20px);
    left: 0;
    right: 0;
    margin: 0 auto 0;
    width: 70%;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);
    padding: 10px 25px;
    border-radius: 16px;
    z-index: 3;

    visibility: hidden;
    opacity: 0;

    & > img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  .topGuidBoxAnimation {
    animation: blockTransition3 @topGuidTime linear forwards @topGuidDelay;
  }

  & > .inputBox {
    width: max-content;
    //filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc(8% - 20px);
    margin: 0 auto;
    z-index: 3;

    visibility: hidden;
    opacity: 0;
    @padding: min(2vw, 35px);
    & > .button {
      font-weight: 600;
      color: #000000;
      padding: 0 @padding;
      //max-width: 280px;
      height: min(8vw, 64px) !important;
      font-size: min(2vw, 17px) !important;
      background: #f5ab15;
      border: 3px solid #ffffff;
      border-radius: 16px;
      box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.28);
      outline: none;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        background: #e79b00;
        box-shadow: none;
      }

      & > svg {
        height: 25%;
        margin-left: 10px;
        position: relative;
        top: 2px;
      }
    }
  }

  & > .inputBoxAnimation {
    animation: blockTransition4 @inputBoxTime linear forwards @inputBoxDelay;
  }

  .floatContainer {
    position: absolute;
    top: 0;
    margin-top: calc(17.5% + 20px);
    width: 32%;
    background: rgba(255, 255, 255, 0.45);
    box-sizing: border-box;
    padding: max(2.5%, 20px);
    border-radius: 14px;
    box-shadow: 0 30px 50px 0 rgba(17, 18, 60, 0.25);
    //transform: translateY(50px);
    backdrop-filter: blur(15px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(15px);

    visibility: hidden;
    opacity: 0;

    & > img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
      //-webkit-backdrop-filter: blur(5px);
    }
  }

  .floatContainerAnimation {
    transition: margin 0.5s linear, opacity 0.3s linear;
    animation: blockTransition2 @time2 linear forwards @delay2;
  }
}

@keyframes blockTransition1 {
  to {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes blockTransition2 {
  to {
    visibility: visible;
    opacity: 1;
    margin-top: 17.5%;
  }
}

@keyframes blockTransition3 {
  to {
    visibility: visible;
    opacity: 1;
    top: 9%;
  }
}

@keyframes blockTransition4 {
  to {
    visibility: visible;
    opacity: 1;
    bottom: 8%;
  }
}
