.container {
  position: relative;

  & > img {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;

    transform: translateY(10%);
    opacity: 0;
    visibility: hidden;
    transition: transform ease-in 0.5s, opacity ease-in 0.3s;
  }

  & > .button {
    position: absolute;
    bottom: calc(7% - 20px);
    left: 0;
    right: 0;
    margin: 0 auto;

    width: max-content;
    min-width: 210px;
    max-width: 80%;
    padding: 0 min(2vw, 35px);
    height: min(17%, 64px);
    background: #02d190;
    border: 3px solid #ffffff;
    border-radius: 16px;
    box-shadow: 0 10px 20px 4px rgba(0, 0, 0, 0.15);

    font-size: 17px;
    font-weight: bold;
    color: #ffffff;

    cursor: pointer;
    transition: all 0.3s;

    visibility: hidden;
    opacity: 0;

    &:hover {
      background: #00bd81;
      box-shadow: none;
    }

    & > svg path {
      fill: #ffffff;
    }

    & > svg {
      position: relative;
      top: 3px;
      margin-left: 10px;
    }
  }

  .floatContainer {
    position: absolute;
    z-index: 0;
    left: 0;
    margin-top: 5%;
    width: 38%;
    background: rgba(255, 255, 255, 0.45);
    box-sizing: border-box;
    padding: max(2.5%, 20px);
    border-radius: 14px;
    box-shadow: 0 30px 50px 0 rgba(17, 18, 60, 0.25);
    transform: translateY(50px);
    backdrop-filter: blur(15px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(15px);

    & > img {
      width: auto;
      max-width: 100%;
      //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
      //-webkit-backdrop-filter: blur(5px);
    }
  }
}
