.container {
  position: relative;

  & > .mainBg {
    width: auto;
    height: auto;
    max-width: 81.5%;
    margin-left: 18.5%;
    max-height: 100%;
    object-fit: contain;
    box-sizing: border-box;

    visibility: hidden;
    opacity: 0;
    transform: translateX(10%);
    transition: transform 0.5s linear, opacity 0.3s linear;
  }

  .rightBox {
    width: 24%;
    max-width: 240px;
    position: absolute;
    bottom: 0;
    right: min(3.5%, 36px);
    backdrop-filter: blur(10px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.26);
    padding: min(2.5%, 27px) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    //border-radius: 16px 16px 0 0;
    border-radius: min(1vw, 16px) min(1vw, 16px) 0 0;
    box-shadow: 0 6px 20px 0 rgba(42, 71, 159, 0.2);

    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);

    .rightImg {
      width: 90%;
      height: auto;
      max-width: 208px;
      object-fit: contain;
    }
  }

  .floatContainer {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    margin-top: 6%;
    width: 34%;
    max-width: 340px;
    background: rgba(255, 255, 255, 0.55);
    box-sizing: border-box;
    padding: min(2.5%, 30px) 0;
    border-radius: 14px;
    box-shadow: 0 30px 50px 0 rgba(17, 18, 60, 0.25);
    transform: translateY(20px);
    backdrop-filter: blur(10px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);

    visibility: hidden;
    opacity: 0;

    & > img {
      width: auto;
      height: auto;
      max-width: 91%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
      //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
      //-webkit-backdrop-filter: blur(5px);
    }

    .imgMarginTop12 {
      margin-top: 12px;
    }

    .buttonBox {
      width: 90%;
      max-width: 240px;
      background-color: #31b3fd;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      border-radius: 16px;
      margin: 23px auto 20px auto;
      cursor: pointer;
      transition: all 0.3s;

      height: min(8vw, 64px) !important;
      font-size: min(2vw, 17px) !important;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);

      &:hover {
        background-color: #0892e1;
        box-shadow: none;
      }

      & > svg {
        height: 62.5%;
        object-fit: contain;
        margin-right: 10px;
        //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
        //-webkit-backdrop-filter: blur(5px);
      }
    }
  }
}

// 动画持续时间、动画延迟时间
@time1: 0.5s;
@delay1: 0s;

@time2: 0.3s;
@delay2: 0.5s;

@time3: 0.3s;
@delay3: 0.8s;

.mainBgAnimation {
  transition: transform 0.5s linear, opacity 0.3s linear;
  animation: blockTransition1 @time1 linear forwards @delay1;
}

.floatContainerAnimation {
  transition: transform 0.5s linear, opacity 0.3s linear;
  animation: blockTransition2 @time2 linear forwards @delay2;
}

.rightImgAnimation {
  transition: transform 0.5s linear, opacity 0.3s linear;
  animation: blockTransition2 @time3 linear forwards @delay3;
}

@keyframes blockTransition1 {
  to {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes blockTransition2 {
  to {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

@media all and (max-width: 1450px) {
  .container {
    .floatContainer {
      .buttonBox {
        width: 90%;
        font-size: min(1.5vw, 15px) !important;
        padding: 0 5%;

        //& > svg {
        //  max-width: 15%;
        //}
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .container {
    .floatContainer {
      .buttonBox {
        font-size: min(1.5vw, 17px) !important;
        padding: 0 5%;

        & > svg {
          max-width: 15%;
        }
      }
    }
  }
}
