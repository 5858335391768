.choicenessContainer {
  //background-color: #02030F;
  background-image: url('https://resource.flexclip.com/pages/home/choiceness/banner.webp');
  background-color: #060312;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 120px 0;
  display: flex;
  justify-content: right;
  font-family: Roboto;

  .choiceness {
    //flex-basis: min(95%, calc(1920px - 5%));
    flex-basis: 1820px;
    margin-left: auto;
    padding-left: 20px;

    .title {
      font-size: 50px;
      font-weight: 700;
      text-align: left;
      color: #ffffff;
      line-height: 64px;
      max-width: 620px;
    }

    .titleAnimation {
      opacity: 0;
      transform: translateX(-50px);
    }

    .contentBox {
      display: flex;
      justify-content: space-between;
    }

    .left {
      //flex-basis: 620px;
      //flex-shrink: 1;
      max-width: 620px;
      margin-right: 30px;
      transition: all 1s linear;

      .toolsContainer {
        display: flex;
        list-style: none;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .toolsItem {
          width: 300px;
          box-sizing: border-box;
          padding: 24px 16px 24px 24px;
          border-radius: 16px;
          margin: 20px 1px 0 1px;
          position: relative;
          cursor: pointer;
          align-self: stretch;

          &:hover {
            &:before {
              border: 2px solid rgba(255, 255, 255, 0.4);
            }
          }

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            position: absolute;
            left: 0;
            top: 0;
          }

          & > img {
            height: 56px;
            object-fit: contain;
          }

          .toolsItemTitle {
            font-size: 20px;
            font-weight: bold;
            text-align: left;
            color: #ffffff;
            line-height: 24px;
            margin: 16px 0 8px 0;
          }

          .toolsItemDesc {
            opacity: 0.68;
            font-size: 17px;
            text-align: left;
            color: #ffffff;
            line-height: 24px;
          }
        }

        .toolsItemActive {
          background: rgba(255, 255, 255, 0.08);
          box-shadow: 0 6px 30px 0 rgba(57, 64, 88, 0.15);

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            border: 2px solid transparent;
            position: absolute;
            left: -2px;
            top: -2px;
          }
        }

        .AIVideoGenerator {
          &:before {
            border: 2px solid #4682ff !important;
          }
        }

        .TextToSpeech {
          &:before {
            border: 2px solid #ff8e04 !important;
          }
        }

        .AIScript {
          &:before {
            border: 2px solid #02d190 !important;
          }
        }

        .AIBGRemover {
          &:before {
            border: 2px solid #f5ab15 !important;
          }
        }

        .AutoSubtitle {
          &:before {
            border: 2px solid #2b99fb !important;
          }
        }

        .CurveSpeed {
          &:before {
            border: 2px solid #31b3fd !important;
          }
        }
      }
    }

    .animationInit {
      opacity: 0;
    }

    .right {
      flex-basis: 1018px;
      max-width: calc(100% - 620px);
      height: auto;
      align-self: center;
      //transform: translateX(50px);
    }

    .right0 {
      flex-basis: 1060px;
    }

    .right1 {
      flex-basis: 1038px;
    }
  }
}

.leftTransform {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.rightTransform {
  opacity: 1 !important;
  transform: translateX(0) !important;
}

.swingInTopFwd {
  -webkit-animation: titleAnimation 1s linear forwards;
  animation: titleAnimation 1s linear forwards;
}

@-webkit-keyframes titleAnimation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes titleAnimation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media all and (max-width: 1900px) {
  .choicenessContainer {
    .choiceness {
      .left {
        transition: none !important;
      }

      .right {
        transition: none !important;
      }
    }
  }
}

@media all and (max-width: 1400px) {
  .choicenessContainer {
    .choiceness {
      //min-height: 1520px;
      margin-left: 0;

      .title {
        margin: 0 auto;
        text-align: center;
        max-width: 100%;
      }

      .contentBox {
        flex-direction: column-reverse;

        .left {
          max-width: 952px;
          margin: 0 auto;
        }

        .right {
          width: 90%;
          flex-basis: auto;
          max-width: 952px !important;
          margin: 40px auto;
        }
      }
    }
  }
}

@media all and (max-width: 1060px) {
  .choicenessContainer {
    .choiceness {
      .contentBox {
        .left {
          max-width: 628px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .choicenessContainer {
    .choiceness {
      .contentBox {
        .left {
          max-width: 304px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media all and (max-width: 520px) {
  .choicenessContainer {
    padding: 70px 0;
    .choiceness {
      .title {
        width: calc(100% - 20px);
        text-align: left;
      }

      .left {
        max-width: calc(100% - 20px) !important;
        margin-left: 0 !important;

        .toolsContainer {
          margin-top: 15px;
          .toolsItem {
            width: 100%;
            margin: 10px 1px 0 1px;

            &:first-child {
              margin: 0 1px;
            }

            & > img {
              height: 46px;
              object-fit: contain;
            }

            .toolsItemTitle {
              font-size: 18px;
              line-height: 21px;
            }
            .toolsItemDesc {
              font-size: 15px;
              line-height: 22px !important;
            }
          }
        }
      }

      .right {
        display: none;
      }
    }
  }
}
