.container {
  position: relative;

  & > img {
    @width: min(91%, 892px);
    display: block;
    width: auto;
    height: auto;
    max-width: @width;
    margin-left: calc(100% - @width);
    max-height: 100%;
    object-fit: contain;
    visibility: hidden;
    transform: translateX(10%);
    opacity: 0;
    transition: transform ease-in 0.5s, opacity ease-in 0.3s;
  }

  & > .inputBox {
    margin: 0 20% 0 30%;
    height: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7%;
    z-index: 2;
    scale: calc(100%);
    visibility: hidden;
    transform: translateY(20px);
    opacity: 0;
    transition: transform ease-in 0.5s, opacity ease-in 0.3s;
    //filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));

    backdrop-filter: blur(10px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.2);

    border-radius: min(1vw, 16px);
    padding: 0 20px;

    font-size: min(1.5vw, 25px);
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .floatContainer {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    margin-top: 8%;
    max-width: 41%;
    background: rgba(255, 255, 255, 0.45);
    box-sizing: border-box;
    padding: min(2.5%, 20px);
    border-radius: 14px;
    box-shadow: 0 30px 50px 0 rgba(17, 18, 60, 0.25);
    transform: translateY(20px);
    backdrop-filter: blur(15px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(15px);
    visibility: hidden;

    opacity: 0;
    transition: transform ease-in 0.5s, opacity ease-in 0.3s;

    & > img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
      //-webkit-backdrop-filter: blur(5px);
    }

    .buttonBox {
      max-width: 240px;
      //height: 64px;
      height: min(8vw, 64px) !important;
      background-color: #4682ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: min(2vw, 17px) !important;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      border-radius: 16px;
      margin: 6.5% auto 5% auto;
      cursor: pointer;
      transition: all 0.3s;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);

      &:hover {
        background: #286af5;
        box-shadow: none;
      }

      & > svg {
        height: 62.5%;
        margin-right: 10px;
        //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
        //-webkit-backdrop-filter: blur(5px);
      }
    }
  }
}

//@media all and (max-width: 1590px) {
//  .container {
//    & > .inputBox {
//
//      & > span {
//        font-size: 23px;
//      }
//    }
//  }
//}

@media all and (max-width: 1570px) {
  .container {
    & > .inputBox {
      font-size: min(1.3vw, 25px) !important;
    }
  }
}

@media all and (max-width: 1400px) {
  .container {
    & > .inputBox {
      font-size: min(2.3vw, 25px) !important;
    }
  }
}
