.container {
  position: relative;

  & > .video {
    height: auto;
    max-height: 100%;
    max-width: 84%;
    border-radius: 14px 0 0 14px;
    overflow: hidden;
    margin-left: 16%;

    & > video {
      width: auto;
      max-width: 100%;
      object-fit: cover;
      border-radius: inherit;
      transform: translateX(10%);
      opacity: 0;
      visibility: hidden;
      transition: transform ease-in 0.5s, opacity ease-in 0.3s;
    }
  }

  @height: min(60px, 4.5vw);
  & > .inputBox {
    max-width: 61%;
    //margin: -90px auto 0 auto;
    //margin-top: -90px;
    margin: 0 auto;
    padding: 0 10px;
    height: @height;
    display: flex;
    align-items: center;
    justify-content: center;
    //filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4));
    backdrop-filter: blur(10px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.4);
    //filter: blur(10px);
    //transform: translateX(16%) translateY(-90px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in;
    border-radius: min(1vw, 16px);
    position: relative;
    top: -90px;
    left: 11%;

    & > span {
      display: block;
      transform-origin: center center;
      font-size: min(2vw, 25px) !important;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .floatContainer {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    margin-top: 2%;
    width: 42.5%;
    min-width: 300px;
    background: rgba(255, 255, 255, 0.75);
    box-sizing: border-box;
    padding: max(2.5%, 30px);
    border-radius: 14px;
    box-shadow: 0 30px 50px 0 rgba(17, 18, 60, 0.25);
    backdrop-filter: blur(10px); /* 设置内容区域的毛玻璃效果 */
    -webkit-backdrop-filter: blur(10px);

    transform: translateY(70px);
    visibility: hidden;
    opacity: 0;
    transition: transform ease-in 0.5s, opacity ease-in 0.3s;

    .floatTopImg {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }

    .buttonBox {
      width: max-content;
      min-width: 240px;
      height: 64px;
      padding: 0 12px;
      background-color: rgb(43, 153, 251);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      border-radius: 16px;
      margin: 23px auto 20px auto;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: #1187ef;
        box-shadow: none;
      }

      & > img {
        width: 40px;
        margin-right: 10px;
        //backdrop-filter: blur(5px); /* 设置内容区域的毛玻璃效果 */
        //-webkit-backdrop-filter: blur(5px);
      }
    }
  }
}

@media all and (max-width: 1700px) {
  .container {
    & > .inputBox {
      & > span {
        font-size: min(1.4vw, 25px) !important;
      }
    }
  }
}

@media all and (max-width: 1600px) {
  .container {
    & > .inputBox {
      & > span {
        font-size: min(1.3vw, 25px) !important;
      }
    }
  }
}

@media all and (max-width: 1570px) {
  .container {
    & > .inputBox {
      & > span {
        font-size: min(1.2vw, 25px) !important;
      }
    }
  }
}

@media all and (max-width: 1400px) {
  .container {
    & > .inputBox {
      & > span {
        font-size: min(2.1vw, 25px) !important;
      }
    }
  }
}

@media all and (max-width: 860px) {
  .container {
    & > .inputBox {
      & > span {
        font-size: min(2vw, 25px) !important;
      }
    }
  }
}
